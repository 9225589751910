/// <reference types="vite/client" />
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import('./App')
  .then((module) => module.default())
  .catch((err) => {
    const chunkFailedMessage = /Loading chunk (.*)+ failed/;

    if (chunkFailedMessage.test(err.message)) {
      window.location.reload();
    }
  });
